// import useWindowSize from '@rehooks/window-size'
import React from 'react'
// import { size } from './Styled/Breakpoints'

function LineBreak() {
  // let windowsize = 900
  // if (typeof window !== 'undefined') {
  //   windowsize = useWindowSize()
  // }
  // let breakpoint = size.laptop.replace('px', '')
  // return windowSize.innerWidth > parseInt(breakpoint) ? <br /> : <>&nbsp;</>
  return <br/>
}

export default LineBreak
