import React, { useEffect, useRef, useState, useContext } from 'react'

import {
  Col,
  Container,
  Half,
  Image,
  ImageContainer,
  Row,
  Text,
} from '../../components/Styled/Grid.js'

import Imgix, { Picture, Source } from 'react-imgix'
import {
  Body,
  Bullet,
  Emphasis,
  SubTitle,
  Title,
} from '../../components/Styled/Type.js'
import { useWindowSize } from '../../helpers'
import Cookies from 'universal-cookie'
import Br from '../../components/LineBreak'

const ImageCarousel = () => {
  let windowSize = useWindowSize()
  const [index, setIndex] = useState(5)

  useEffect(() => {
    const cookies = new Cookies()
    let src = cookies.get('src')
    switch (src) {
      case 'home':
        setIndex(0)
        return
      case 'art':
        setIndex(1)
        return
      case 'family':
        setIndex(2)
        return
      case 'office':
        setIndex(3)
        return
      case 'retail':
        setIndex(4)
        return
      default:
        setIndex(0)
        return
    }
  }, [])

  const changeSlide = (e, slide) => {
    e.preventDefault()
    setIndex(slide)
  }

  return (
    <ImageContainer window={windowSize.windowWidth}>
      {index !== 5 && (
        <>
          <div
            className={index === 1 ? 'fade-in' : 'fade-out'}
            style={{ position: 'absolute', top: 0 }}
          >
            <Picture style={{ position: 'absolute', top: 0 }}>
              <Source
                src={
                  'https://jotowebsite.imgix.net/lounge_wide_2.jpg?fit=crop&crop=focalpoint&fp-x=.625&fp-y=.5&fp-z=1.4'
                }
                htmlAttributes={{ media: '(min-width: 1024px)' }}
                imgixParams={{ ar: '6:3' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/lounge_wide_2.jpg?fit=crop&fp-x=.925&fp-y=.725&fp-z=2'
                }
                htmlAttributes={{ media: '(min-width: 768px)' }}
                imgixParams={{ ar: '1:1.1' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/lounge_wide_2.jpg?fit=crop&crop=focalpoint&fp-x=.76&fp-y=.2&fp-z=1.1'
                }
                imgixParams={{ ar: '1:1.5' }}
                htmlAttributes={{ media: '(min-width: 480px)' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/lounge_wide_2.jpg?fit=crop&crop=focalpoint&fp-x=.76&fp-y=.2&fp-z=1.25'
                }
                imgixParams={{ ar: '1:1.5' }}
                htmlAttributes={{ media: '(min-width: 320px)' }}
              />
              <Imgix
                src={
                  'https://jotowebsite.imgix.net/lounge_wide_2.jpg?fit=crop&crop=focalpoint&fp-x=.76&fp-y=.4&fp-z=1.3'
                }
                imgixParams={{ ar: '9:25' }}
              />
            </Picture>
          </div>
          <div
            className={index === 0 ? 'fade-in' : 'fade-out'}
            style={{ position: 'absolute', top: 0 }}
          >
            <Picture>
              <Source
                src={
                  'https://jotowebsite.imgix.net/home_family.jpg?fit=crop&crop=focalpoint&fp-x=.625&fp-y=.5&fp-z=1.4'
                }
                htmlAttributes={{ media: '(min-width: 1024px)' }}
                imgixParams={{ ar: '6:3' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/home_family.jpg?fit=crop&fp-x=.9&fp-y=.5&fp-z=2'
                }
                htmlAttributes={{ media: '(min-width: 768px)' }}
                imgixParams={{ ar: '1:1.1' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/home_family.jpg?fit=crop&crop=focalpoint&fp-x=.99&fp-y=.4&fp-z=1'
                }
                imgixParams={{ ar: '1:1.5' }}
                htmlAttributes={{ media: '(min-width: 480px)' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/home_family.jpg?fit=crop&crop=focalpoint&fp-x=.9&fp-y=.3&fp-z=1.1'
                }
                imgixParams={{ ar: '1:1.5' }}
                htmlAttributes={{ media: '(min-width: 320px)' }}
              />
              <Imgix
                src={
                  'https://jotowebsite.imgix.net/home_family.jpg?fit=crop&crop=focalpoint&fp-x=.76&fp-y=.2&fp-z=1'
                }
                imgixParams={{ ar: '9:25' }}
              />
            </Picture>
          </div>
          <div
            className={index === 2 ? 'fade-in' : 'fade-out'}
            style={{ position: 'absolute', top: 0 }}
          >
            <Picture>
              <Source
                src={
                  'https://jotowebsite.imgix.net/kids_full_light.jpg?fit=crop&crop=focalpoint&fp-x=.425&fp-y=.7&fp-z=1.4'
                }
                htmlAttributes={{ media: '(min-width: 1024px)' }}
                imgixParams={{ ar: '6:3' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/kids_full_light.jpg?fit=crop&fp-x=.5&fp-y=.725&fp-z=2'
                }
                htmlAttributes={{ media: '(min-width: 768px)' }}
                imgixParams={{ ar: '1:1.1' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/kids_full_light.jpg?fit=crop&crop=focalpoint&fp-x=.56&fp-y=.6&fp-z=1.1'
                }
                imgixParams={{ ar: '1:1.5' }}
                htmlAttributes={{ media: '(min-width: 480px)' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/kids_full_light.jpg?fit=crop&crop=focalpoint&fp-x=.5&fp-y=.6&fp-z=0.8'
                }
                imgixParams={{ ar: '1:1.5' }}
                htmlAttributes={{ media: '(min-width: 320px)' }}
              />
              <Imgix
                src={
                  'https://jotowebsite.imgix.net/kids_full_light.jpg?fit=crop&crop=focalpoint&fp-x=.6&fp-y=.1&fp-z=1'
                }
                imgixParams={{ ar: '9:25' }}
              />
            </Picture>
          </div>
          <div
            className={index === 3 ? 'fade-in' : 'fade-out'}
            style={{ position: 'absolute', top: 0 }}
          >
            <Picture>
              <Source
                src={
                  'https://jotowebsite.imgix.net/meeting_fade_dark.jpg?fit=crop&crop=focalpoint&fp-x=.53&fp-y=.5&fp-z=1.4'
                }
                htmlAttributes={{ media: '(min-width: 1024px)' }}
                imgixParams={{ ar: '6:3' }}
              />
              <Source
                vb
                src={
                  'https://jotowebsite.imgix.net/meeting_fade_dark.jpg?fit=crop&fp-x=.9&fp-y=3&fp-z=2'
                }
                htmlAttributes={{ media: '(min-width: 768px)' }}
                imgixParams={{ ar: '1:1.1' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/meeting_fade_dark.jpg?fit=crop&crop=focalpoint&fp-x=.65&fp-y=.4&fp-z=1.2'
                }
                imgixParams={{ ar: '1:1.5' }}
                htmlAttributes={{ media: '(min-width: 480px)' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/meeting_fade_dark.jpg?fit=crop&crop=focalpoint&fp-x=.65&fp-y=.3&fp-z=1.15'
                }
                imgixParams={{ ar: '1:1.5' }}
                htmlAttributes={{ media: '(min-width: 320px)' }}
              />
              <Imgix
                src={
                  'https://jotowebsite.imgix.net/meeting_fade_dark.jpg?fit=crop&crop=focalpoint&fp-x=.64&fp-y=.3&fp-z=1.25'
                }
                imgixParams={{ ar: '9:25' }}
              />
            </Picture>
          </div>
          <div className={index === 4 ? 'fade-in' : 'fade-out'}>
            <Picture>
              <Source
                src={
                  'https://jotowebsite.imgix.net/retail_wide_sm.jpg?fit=crop&crop=focalpoint&fp-x=.425&fp-y=.7&fp-z=1.4'
                }
                htmlAttributes={{ media: '(min-width: 1024px)' }}
                imgixParams={{ ar: '6:3' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/retail_wide_sm.jpg?fit=crop&fp-x=.5&fp-y=.725&fp-z=2'
                }
                htmlAttributes={{ media: '(min-width: 768px)' }}
                imgixParams={{ ar: '1:1.1' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/retail_wide_sm.jpg?fit=crop&crop=focalpoint&fp-x=.56&fp-y=.4&fp-z=1.1'
                }
                imgixParams={{ ar: '1:1.5' }}
                htmlAttributes={{ media: '(min-width: 480px)' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/retail_wide_sm.jpg?fit=crop&crop=focalpoint&fp-x=.5&fp-y=.4&fp-z=0.8'
                }
                imgixParams={{ ar: '1:1.5' }}
                htmlAttributes={{ media: '(min-width: 320px)' }}
              />
              <Imgix
                src={
                  'https://jotowebsite.imgix.net/retail_wide_sm.jpg?fit=crop&crop=focalpoint&fp-x=.76&fp-y=.3&fp-z=1.3'
                }
                imgixParams={{ ar: '9:25' }}
              />
            </Picture>
          </div>
        </>
      )}
      <>
        <div className={'carousel-anchors'}>
          <a
            className={`carousel-anchor fade-text ${
              index === 3 ? 'invert' : null
            }  ${index === 0 ? 'active' : null}`}
            href={'#'}
            onClick={e => changeSlide(e, 0)}
          >
            {'Home'}
          </a>
          <a
            className={`carousel-anchor fade-text ${
              index === 3 ? 'invert' : null
            }  ${index === 1 ? 'active' : null}`}
            href={'#'}
            onClick={e => changeSlide(e, 1)}
          >
            {'Art'}
          </a>
          <a
            className={`carousel-anchor fade-text ${
              index === 3 ? 'invert' : null
            }  ${index === 2 ? 'active' : null}`}
            href={'#'}
            onClick={e => changeSlide(e, 2)}
          >
            {'Family'}
          </a>
          <a
            className={`carousel-anchor fade-text ${
              index === 3 ? 'invert' : null
            }  ${index === 3 ? 'active' : null}`}
            href={'#'}
            onClick={e => changeSlide(e, 3)}
          >
            {'Office'}
          </a>
          <a
            className={`carousel-anchor fade-text ${
              index === 3 ? 'invert' : null
            }  ${index === 4 ? 'active' : null}`}
            href={'#'}
            onClick={e => changeSlide(e, 4)}
          >
            {'Retail'}
          </a>
        </div>
        <Text window={windowSize.windowWidth} white carousel padded>
          {index === 0 && (
            <Title
              className={`fade-text ${index === 3 ? 'invert' : null} ${
                index === 0 ? 'fade-in' : 'fade-out'
              }`}
            >
              Create and share your own
              <Br />
              content with family and friends.
            </Title>
          )}

          {index === 1 && (
            <Title
              className={`fade-text ${index === 3 ? 'invert' : null} ${
                index === 1 ? 'fade-in' : 'fade-out'
              }`}
            >
              The perfect work of art
              <Br />
              for your home.
            </Title>
          )}

          {index === 2 && (
            <Title
              className={`fade-text ${index === 3 ? 'invert' : null} ${
                index === 2 ? 'fade-in' : 'fade-out'
              }`}
            >
              Create and share your own content
              <Br />
              with family and friends
            </Title>
          )}

          {index === 3 && (
            <Title
              className={`fade-text ${index === 3 ? 'invert' : null} ${
                index === 3 ? 'fade-in' : 'fade-out'
              }`}
            >
              Let the Joto do
              <Br />
              the work!
            </Title>
          )}

          {index === 4 && (
            <Title
              className={`fade-text ${index === 3 ? 'invert' : null} ${
                index === 4 ? 'fade-in' : 'fade-out'
              }`}
            >
              Engage customers with
              <Br />
              mesmerising signage.
            </Title>
          )}

          {index === 0 && (
            <Body className={`fade-text ${index === 3 ? 'invert' : null}`}>
              Hang Joto in the kitchen and it’s a family to-do-list. Pop it up
              in a cafe and it's an updatable menu. Or use it in the workplace
              and it’s a nifty noticeboard. Joto is also easy to update, so you
              can choose different content throughout the day to suit your mood.
            </Body>
          )}

          {index === 1 && (
            <Body className={`fade-text ${index === 3 ? 'invert' : null}`}>
              Hang Joto in the kitchen and it’s a family to-do-list. Pop it up
              in a cafe and it's an updatable menu. Or use it in the workplace
              and it’s a nifty noticeboard. Joto is also easy to update, so you
              can choose different content throughout the day to suit your mood.
            </Body>
          )}

          {index === 2 && (
            <Body className={`fade-text ${index === 3 ? 'invert' : null}`}>
              Hang Joto in the kitchen and it’s a family to-do-list. Pop it up
              in a cafe and it's an updatable menu. Or use it in the workplace
              and it’s a nifty noticeboard. Joto is also easy to update, so you
              can choose different content throughout the day to suit your mood.
            </Body>
          )}

          {index === 3 && (
            <Body className={`fade-text ${index === 3 ? 'invert' : null}`}>
              Hang Joto in the kitchen and it’s a family to-do-list. Pop it up
              in a cafe and it's an updatable menu. Or use it in the workplace
              and it’s a nifty noticeboard. Joto is also easy to update, so you
              can choose different content throughout the day to suit your mood.
            </Body>
          )}

          {index === 4 && (
            <Body className={`fade-text ${index === 3 ? 'invert' : null}`}>
              Hang Joto in the kitchen and it’s a family to-do-list. Pop it up
              in a cafe and it's an updatable menu. Or use it in the workplace
              and it’s a nifty noticeboard. Joto is also easy to update, so you
              can choose different content throughout the day to suit your mood.
            </Body>
          )}
        </Text>
      </>
    </ImageContainer>
  )
}


export default ImageCarousel