import Amplify from 'aws-amplify'
import React from 'react'
import { AmplifyConfig } from '../config'
import Home from '../Screens/Home'
import Layout from '../components/Global/Layout'
import Seo from '../components/Global/Seo'

Amplify.configure(AmplifyConfig)

const Test = () => {
  return <h1>Test</h1>
}
const IndexPage = () => (
  <Layout>
    <Seo />
    <Home />
  </Layout>
)

export default IndexPage
