import { faPlayCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
//// REASSURANCE
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWindowSize } from '../helpers'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { Award, Lock, Tool, Truck, Users } from 'react-feather'
import Imgix, { Picture, Source } from 'react-imgix'
import { useDispatch, useSelector } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Modal } from 'reactstrap'
import Cookies from 'universal-cookie'
import android from '../assets/images/android.jpg'
import appstore from '../assets/images/appstore.jpg'
import eraser from '../assets/images/eraser.jpg'
import ink from '../assets/images/ink.jpg'
import logo1 from '../assets/images/logo1.png'
import logo2 from '../assets/images/logo2.png'
import logo3 from '../assets/images/logo3.png'
import logo4 from '../assets/images/logo4.png'
import logo5 from '../assets/images/logo5.png'
import logo6 from '../assets/images/logo6.png'
import pen from '../assets/images/pen.jpg'
import phone from '../assets/images/phone.jpg'
import phoneApp from '../assets/images/phone2.png'
import Popup from '../assets/images/popup.jpg'
import shapes2 from '../assets/images/shapes2.png'
import wifi from '../assets/images/wifi.jpg'
import Br from '../components/LineBreak'
import { colors } from '../components/Styled/Colors'
import { Button, VideoContainer } from '../components/Styled/Components'
import {
  Col,
  Container,
  Half,
  Image,
  ImageContainer,
  Row,
  Text,
} from '../components/Styled/Grid.js'
import {
  Body,
  Bullet,
  Emphasis,
  SubTitle,
  Title,
} from '../components/Styled/Type.js'
//// MODAL
import MailchimpForm from '../Containers/MailchimpInput'
import ProductLoop from '../Containers/ProductLoop'
///// SHOP
import { client } from '../State/cart/operations'
import { grommet, Box, Card, Grid, ResponsiveContext } from 'grommet'
import ImageCarousel from '../components/Elements/ImageCarousel'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss'

const Icon = ({ children }) => {
  return (
    <div
      style={{
        borderRadius: '50%',
        borderStyle: 'solid',
        borderWidth: 3,
        borderColor: colors.black,
        padding: '1rem',
        height: '4rem',
        width: '4rem',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
      }}
    >
      {children}
    </div>
  )
}

const ReassuranceIcon = ({ icon, title, subtitle }) => {
  return (
    <Box align="center">
      <Icon>{icon}</Icon>
      <Bullet
        style={{ textAlign: 'center', fontSize: '0.85rem' }}
        color={colors.black}
      >
        {title}
      </Bullet>
      {subtitle && (
        <Body
          color={colors.black}
          small
          style={{ marginTop: '-0.5rem', fontSize: '0.6rem' }}
        >
          {subtitle}
        </Body>
      )}
    </Box>
  )
}

const Reassurance = () => {
  const size = useContext(ResponsiveContext)
  return (
    <Container color={colors.pastelGreen}>
      <Box fill={'horizontal'}>
        <Bullet
          style={{ marginBottom: 40, textAlign: 'center' }}
          color={colors.black}
        >
          {'With every Joto purchase...'}
        </Bullet>
      </Box>

      <Box fill={'horizontal'}>
        <Grid
          columns={{
            count: size === 'small' ? 2 : 5,
            size: 'auto',
          }}
          gap="small"
        >
          <ReassuranceIcon
            title="Free Joto Delivery"
            subtitle="UK & Europe"
            icon={<Truck color={colors.black} size={48} />}
          />
          <ReassuranceIcon
            title="Your privacy a priority"
            icon={<Lock color={colors.black} size={48} />}
          />
          <ReassuranceIcon
            title="1 Year Warranty"
            icon={<Award color={colors.black} size={48} />}
          />
          <ReassuranceIcon
            title="Personal Support"
            icon={<Tool color={colors.black} size={48} />}
          />
          <ReassuranceIcon
            title="Join A Community"
            icon={<Users color={colors.black} size={48} />}
          />
        </Grid>
      </Box>
    </Container>
  )
}

const faqData = [
  {
    q:
      ' How am I able to send drawings & messages to the Joto from anywhere in the world?',
    a:
      ' Simply download the Joto app (available on iOS and Android) to freehand draw, type, emojis, stickers, import SVGs or discover Jots from other community members.',
  },

  {
    q: ' Can I use other design programs outside of your app?',
    a:
      ' The Joto app allows you to import SVGs so you can use programs like Adobe Illustrator to create your Jots.',
  },

  {
    q: 'What colour ink does the Joto draw and how long does it last?',
    a:
      'We supply the ink from Edding in Black, but you can get other colours. It lasts up to 6 months depending on how often you Jot.',
  },

  {
    q: 'Can you use any marker pen?',
    a:
      " No. Jot has an in-built pen and a special dock that means the pen doesn't dry out and its always ready to Jot. There's no expensive consumables as Joto uses ink that readily available off the shelf. You just need to keep the ink topped up from time to time.",
  },

  {
    q: 'How do I secure the Joto to my wall?',
    a:
      'Joto comes with mounting screws if you would like to mount it on the wall. If not, many users place it on a desk, against a wall, or on a kitchen worktop. We sell a Bamboo Stand that allows you place Joto on any flat surface.',
  },
]

const faqData2 = [
  {
    q: 'Is it battery operated, or does it plug into wall?',
    a:
      "Joto is mains powered by USB-C. It is possible to run Joto off a USB-C power back, but we don't supply this.",
  },

  {
    q: 'Do you have designs that can automatically draw on the Joto?',
    a:
      'Yes, we have launched our first subscription call 365 Days of Art that allows you to have a new piece of work on your wall from world renowned artists and illustrators.',
  },

  {
    q: 'If I order today when will I receive it?',
    a:
      'It depends on where you are located but is expected to be 3 days UK, 6 days EU, 10 days US, ROW please enquire. Depending on Seasonal and COVID restrictions.',
  },

  {
    q: 'What if I have a problem setting up the Joto?',
    a:
      'We have in app user guides with helpful step by step videos, a community forum with lots of help, and live chat if you need a hand with anything – we will do everything we can to help.',
  },

  {
    q: 'I am based in Europe is your Joto compatible here?',
    a:
      'Yes we have compatible plug adapters for Joto to work in UK, US and EU.',
  },

  {
    q: 'What other products do you sell?',
    a:
      'Here at Joto we are experts in drawing machines, we make custom drawing machines for art installations, exhibitions and shop windows. If you would like to know more, get in touch.',
  },
]

const FAQItem = ({ item }) => {
  const [show, setShow] = useState(false)

  const showItem = e => {
    e.preventDefault()
    setShow(show => !show)
  }

  return (
    <Row style={{ transition: 'all 2s' }}>
      <a
        href={'#'}
        onClick={showItem}
        style={{ cursor: 'pointer', width: '100%', paddingRight: '0.5rem' }}
      >
        <Body style={{ marginTop: '1rem', marginBottom: '0.2rem' }} bold>
          {item.q}
        </Body>
      </a>
      <div
        className={`answer ${show ? 'show' : 'hide'}`}
        style={{ paddingRight: '0.5rem' }}
      >
        <Body color={'#999999'} small>
          {item.a}
        </Body>
      </div>
    </Row>
  )
}

const FAQs = () => {
  return (
    <Container>
      <Row>
        <Title>{'FAQs'}</Title>
      </Row>
      <Row>
        <Col>
          {faqData.map((item, key) => {
            return <FAQItem key={key} item={item} />
          })}
        </Col>

        <Col>
          {faqData2.map((item, key) => {
            return <FAQItem key={key} item={item} />
          })}
        </Col>
      </Row>
    </Container>
  )
}

const thisClient = client

const WatchVideo = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <ModalVideo
        channel="vimeo"
        autoplay
        isOpen={isOpen}
        videoId="488753896"
        onClose={() => setOpen(false)}
      />

      <a
        style={{
          color: 'black',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'left',
          alignItems: 'left',
          justifyContent: 'left',
          position: 'relative',
          left: '20px',
        }}
        onClick={() => setOpen(true)}
      >
        <span
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            style={{ marginRight: 10 }}
            size="lg"
            icon={faPlayCircle}
          />
          <Text>Watch The Video</Text>
        </span>
      </a>
    </>
  )
}

function App({ history }) {
  const inputRef = useRef()
  const deviceRef = useRef(null)

  const [toggler, setToggler] = useState(false)

  const videoOptions = {
    src:
      'https://player.vimeo.com/external/380761056.hd.mp4?s=1281b31d8119e8bdfe7af4e2da841efeb42feb2d&profile_id=175',
    autoPlay: true,
  }

  return (
    <>
      <Container>
        <Row>
          <Half>
            <VideoContainer>
              <video
                style={{ width: '100%' }}
                id="background-video"
                loop
                muted
                autoPlay
                webkit-playsinline={'true'}
                playsInline={true}
                src="https://player.vimeo.com/external/380761056.hd.mp4?s=1281b31d8119e8bdfe7af4e2da841efeb42feb2d&profile_id=175"
              >
                <source
                  src="https://player.vimeo.com/external/380761056.hd.mp4?s=1281b31d8119e8bdfe7af4e2da841efeb42feb2d&profile_id=175"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <img
                style={{
                  position: 'absolute',
                  top: '10%',
                  width: '140%',
                  maxWidth: 'none',
                  left: '-26%',
                  right: 0,
                  margin: '0 auto',
                }}
                src={shapes2}
              />
            </VideoContainer>
            <img
              style={{
                position: 'absolute',
                width: '45%',
                bottom: '-5%',
                right: '-5%',
              }}
              src={phoneApp}
            />
          </Half>
          <Half middle right style={{ paddingTop: 50 }}>
            <Text white>
              <Title>
                Send messages or drawings
                <Br />
                <Emphasis color={colors.purple}>
                  from anywhere in the world.
                </Emphasis>
              </Title>
              <Body>
                Say hello to Joto, the first connected whiteboard that draws
                with a pen. Send drawings to your walls or turn your home into
                an ever-changing gallery. Joto takes requests, sketching
                everything from works of art to love notes, to-do lists to
                puzzles.
              </Body>

              {/* <OfferPopup /> */}

              <Button
                color={colors.purple}
                onClick={() =>
                  deviceRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                  })
                }
              >
                Buy Now
              </Button>
              <WatchVideo />
            </Text>
          </Half>
        </Row>
      </Container>
      <Container>
        <Row nostack>
          <Col>
            <a
              href={
                'https://www.dezeen.com/2017/03/14/joto-drawing-robot-etch-sketch-digital-age-those-design-products/'
              }
              target={'_blank'}
            >
              <Image src={logo1} alt="img" />
            </a>
          </Col>
          <Col>
            <a
              href={
                'https://www.stuff.tv/hot-stuff/tech-toys/jotos-alexa-connected-whiteboard-not-teenage-boys'
              }
              target={'_blank'}
            >
              <Image src={logo2} alt="img" />
            </a>
          </Col>
          <Col>
            <a
              href={
                'https://www.theverge.com/circuitbreaker/2017/3/15/14933098/joto-robotic-whiteboard-notifications-kickstarter-art-board-drawing'
              }
              target={'_blank'}
            >
              <Image src={logo4} alt="img" />
            </a>
          </Col>
          <Col>
            <a
              href={'https://www.facebook.com/watch/?v=1301896459931327'}
              target={'_blank'}
            >
              <Image src={logo5} alt="img" />
            </a>
          </Col>
          <Col>
            <a
              href={
                'https://www.itsnicethat.com/articles/joto-here-fran-caballero-martina-paukova-140617'
              }
              target={'_blank'}
            >
              <Image src={logo6} alt="img" />
            </a>
          </Col>
          <Col>
            <a
              href={'https://www.instagram.com/p/BRsmDvejfPR/?hl=en'}
              target={'_blank'}
            >
              <Image src={logo3} alt="img" />
            </a>
          </Col>
        </Row>
      </Container>

      <Box fill="horizontal">
        <ImageCarousel />
      </Box>

      <Container>
        <Row>
          <SubTitle>Key features</SubTitle>
        </Row>
        <Row>
          <Col size={6} stack>
            <Col size={2}>
              <Image left src={eraser} />
            </Col>
            <Col size={8}>
              <Bullet>Eraser</Bullet>
              <Body>
                All automatic! Joto has a wipeable surface that allows it to
                refresh itself at the push of a button.
              </Body>
            </Col>
          </Col>
          <Col size={6} stack>
            <Col size={2}>
              <Image left src={pen} />
            </Col>
            <Col size={8}>
              <Bullet>Refillable Pen</Bullet>
              <Body>
                Joto’s custom pen returns to the dock, so it doesn’t dry out and
                refills ready for the next jot. Also, you can replace the nib
                whenever you need.
              </Body>
            </Col>
          </Col>
          <Col size={6} stack>
            <Col size={2}>
              <Image left src={ink} />
            </Col>
            <Col size={8}>
              <Bullet>No expensive consumables</Bullet>
              <Body>
                The first generation of Jotos has been designed with a
                refillable ink dock, so that you can keep Joto running without
                needing expensive refills.
              </Body>
            </Col>
          </Col>
          <Col size={6} stack>
            <Col size={2}>
              <Image left src={wifi} />
            </Col>
            <Col size={8}>
              <Bullet>Wifi Connected</Bullet>
              <Body>
                Send Jots to family and friends from wherever you are in the
                world! All you need is a wifi connection to get up & jotting.
              </Body>
            </Col>
          </Col>
        </Row>
      </Container>

      <MainProduct ref={deviceRef} />

      <Reassurance />

      <FAQs />

      {/* <StickyContainer style={{ width: "100%", height: "350vh" }}>
        <Sticky topOffset={-100}>
          {({ style, distanceFromTop, isSticky }) => {
            return (
              <Header
                style={{ ...style, paddingTop: 100 }}
                distanceFromTop={distanceFromTop}
              />
            );
          }}
        </Sticky>
      </StickyContainer> */}
      <Box fill="horizontal">
        <Picture>
          <Source
            src={'https://jotowebsite.imgix.net/AirbnbShots2.jpg'}
            htmlAttributes={{ media: '(min-width: 1024px)' }}
            imgixParams={{ ar: '16:9' }}
          />
          <Source
            src={
              'https://jotowebsite.imgix.net/AirbnbShots2.jpg?fit=crop&fp-x=.425&fp-y=.35&fp-z=2'
            }
            htmlAttributes={{ media: '(min-width: 768px)' }}
            imgixParams={{ ar: '1:1' }}
          />
          <Source
            src={
              'https://jotowebsite.imgix.net/AirbnbShots2.jpg?fit=crop&fp-x=.325&fp-y=.35&fp-z=2'
            }
            imgixParams={{ ar: '9:12' }}
            htmlAttributes={{ media: '(min-width: 320px)' }}
          />
          <Imgix
            src={
              'https://jotowebsite.imgix.net/AirbnbShots2.jpg?fit=crop&fp-x=.325&fp-y=.35&fp-z=2'
            }
            imgixParams={{ ar: '9:14' }}
          />
          {/* It seems some things in life are just better off screen! */}
        </Picture>
      </Box>
    </>
  )
}

export default App

const MainProduct = React.forwardRef(({ loading, history, ...rest }, myRef) => {
  const products = useSelector(state => state.cart.products)

  let windowSize = useWindowSize()

  let devices = products.filter(product => product.productType === 'Devices')
  let consumables = products.filter(
    product => product.productType === 'Consumables'
  )
  let addons = products.filter(
    product =>
      product.productType === 'Addons' ||
      product.productType === 'Accessories' ||
      product.productType === 'Subscription'
  )

  useEffect(() => {
    document.body.classList.add('shop')
  }, [])

  return (
    <div ref={myRef}>
      {devices && (
        <ProductLoop
          client={thisClient}
          type={'devices'}
          products={devices}
          {...rest}
        />
      )}
    </div>
  )
})
